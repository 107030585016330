var swRegistration = null;

var PERMISSION_DEFAULT = "default",
    PERMISSION_GRANTED = "granted",
    PERMISSION_DENIED = "denied",
    PERMISSION = [PERMISSION_GRANTED, PERMISSION_DEFAULT, PERMISSION_DENIED];

var config = {
    apiKey: "AIzaSyDX8cJxWS8_x-6remzRqT8CvQSSpopnC2Y",
    authDomain: "historianotif.firebaseapp.com",
    databaseURL: "https://historianotif.firebaseio.com",
    projectId: "historianotif",
    storageBucket: "historianotif.appspot.com",
    messagingSenderId: "604572804153",
    appId: "1:604572804153:web:d79f66a42c08c76e"
};
firebase.initializeApp(config);

var messaging = firebase.messaging();
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(function (registration) {
            swRegistration = registration;
        }, function (err) {
        })
    });
}

var isNotifSupported = !!(window.Notification || win.webkitNotifications || navigator.mozNotification);
var permission = checkPermission();
var notifPathArray = window.location.pathname.split('/');
var lengthParhArray = notifPathArray.length;
var isNotifOnPage = false;
// home path validation
if(lengthParhArray == 2){
  if(notifPathArray[1] == "") isNotifOnPage = true;
}
// detail article path validation
if(lengthParhArray == 4){
  if(notifPathArray[2] == "articles") isNotifOnPage = true;
}

if (typeof Storage !== "undefined" && isNotifSupported && permission !== PERMISSION_DENIED && permission !== PERMISSION_GRANTED) {
    if(isAllowDrawNotif()) {
      if(isNotifOnPage) drawNotifPrompt();
    }
} else {
    requestNotifPermission();
}

function isAllowDrawNotif() {
    var noCount = localStorage.getItem("draw_notif_clicked_no_count");
    var expired = localStorage.getItem("draw_notif_cooldown_expired");
    if (noCount == null && expired == null) {
        return true;
    }
    var currentMillis = new Date().getTime();
    return currentMillis > parseInt(expired);
}

function drawNotifPrompt(){
  var appendNotif = ''
  appendNotif += '<div class="notif-wrap">'
  appendNotif += '  <div class="notif-content">'
  appendNotif += '    <div class="notif-logo">'
  appendNotif += '      <img src="/web/assets/img/historia_logo_instagram.jpg" alt="historia">'
  appendNotif += '    </div>'
  appendNotif += '    <div class="notif-text">'
  appendNotif += '      Nyalakan notifikasi untuk mendapatkan update terbaru dari Historia'
  appendNotif += '    </div>'
  appendNotif += '  </div>'
  appendNotif += '  <div class="notif-action">'
  appendNotif += '      <button onclick="rejectedNotifHandler()">Tidak</button>'
  appendNotif += '      <button class="approved" onclick="requestNotifPermission()">Ya</button>'
  appendNotif += '  </div>'
  appendNotif += '</div>'
  document.getElementsByTagName("body")[0].insertAdjacentHTML('afterbegin', appendNotif);
}

function checkPermission() {
    var permission;
    if (window.webkitNotifications && window.webkitNotifications.checkPermission) {
        permission = PERMISSION[window.webkitNotifications.checkPermission()];
    } else if (navigator.mozNotification) {
        permission = PERMISSION_GRANTED;
    } else if (window.Notification && window.Notification.permission) {
        permission = window.Notification.permission;
    }
    return permission;
}

function handleToken(token) {
    if (typeof Storage === "undefined" || localStorage.getItem("FCM_TOKEN_REGISTERED") !== token) {
        setTimeout(function () {
            if (typeof Fingerprint2 === "function") {
                new Fingerprint2().get(function (result, components) {
                    subscribeNotification(token, result);
                })
            } else {
                subscribeNotification(token, "UNDEFINED");
            }
        }, 500);
    }
}

function requestNotifPermission() {
    var notifWrap = document.querySelector('.notif-wrap')
    if(notifWrap) notifWrap.classList.add('collapse')
    if (typeof Storage !== "undefined") {
        localStorage.removeItem("draw_notif_clicked_no_count");
        localStorage.removeItem("draw_notif_cooldown_expired");
    }
    messaging.requestPermission().then(function () {
        return messaging.getToken();
    }).then(function (token) {
        handleToken(token);
    }).catch(function (err) {
    });
    
}

function rejectedNotifHandler() {
    var notifWrap = document.querySelector('.notif-wrap');
    if(notifWrap) notifWrap.classList.add('collapse');
    var hours_in_millis = 60 * 60 * 1000;
    var cooldownMap = {
        "1" : 3 * hours_in_millis,
        "2" : 12 * hours_in_millis,
        "3" : 24 * hours_in_millis,
        "4" : 3 * 24 * hours_in_millis,
        "5" : 7 * 24 * hours_in_millis,
        "6" : 14 * 24 * hours_in_millis,
        "7" : 30 * 24 * hours_in_millis
    }
    var noCount = localStorage.getItem("draw_notif_clicked_no_count");
    var expired = localStorage.getItem("draw_notif_cooldown_expired");
    var incrCount;
    if (noCount == null && expired == null) {
        incrCount = 1;
    } else {
        incrCount = parseInt(noCount) + 1;
    }
    var cooldownIncr = cooldownMap[incrCount] || cooldownMap[7];
    var nextExpired = new Date().getTime() + cooldownIncr;
    localStorage.setItem("draw_notif_clicked_no_count", incrCount);
    localStorage.setItem("draw_notif_cooldown_expired", nextExpired);
}

messaging.onMessage(function (data) {
    if (swRegistration !== undefined && data !== undefined && data.data !== undefined) {
        var notifData = data.data;
        var options = {
            icon: notifData.icon_url,
            badge: notifData.badge,
            vibrate: [200, 100, 200],
            title: notifData.title,
            body: notifData.body,
            requireInteraction: true,
            data: {
                clickURL: notifData.article_url
            }
        };
        if (!/Mobi|Android/i.test(navigator.userAgent)) {
            options["image"] = notifData.image_url;
        }
        swRegistration.showNotification(notifData.content, options);
    }
});

function subscribeNotification(token, uniqueCode) {
    var data = {
        'token': token
    }
    var header = [{
        'X-User-Agent': 'Web-' + uniqueCode
    }]

    actionWebApiRestrict('/v1/push/register_token', data, 'POST', header).done(function (data) {
        if (data.status === 600) {
            if (typeof Storage !== "undefined") {
                localStorage.setItem("FCM_TOKEN_REGISTERED", token);
            }
        }
    })
}